<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Dashboard</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">Dashboard</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

         <div v-else class="container-fluid">
            <!-- Info boxes -->
            <div v-if="userProfile === 'partner'" class="row">
               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-success elevation-1"><i class="fas fa-tags"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ $t('total_of_used_coupons') }}</span>
                        <span class="info-box-number">{{ totalPartnerCoupons }}</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->
            </div>
            <div v-else class="row">
               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-comment"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ `${$t('total_of')} ${$t('comments')}` }}</span>
                        <span class="info-box-number">{{ totalComments }}</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->

               <!-- fix for small devices only -->
               <div class="clearfix hidden-md-up"></div>

               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ `${$t('total_of')} ${$t('signatures')}` }}</span>
                        <span class="info-box-number">{{ totalSignatures }}</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->
               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ `${$t('total_of')} ${$t('clients')}` }}</span>
                        <span class="info-box-number">{{ totalClients }}</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
               <div class="col-md-12">
                  <div class="card">
                     <div class="card-header">
                        <h5 class="card-title">{{ $t('report') }}</h5>

                        <div class="card-tools">
                           <button type="button" class="btn btn-tool" data-card-widget="collapse">
                              <i class="fas fa-minus"></i>
                           </button>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div class="card-body">
                        <div class="row">
                           <div
                              :class="[ userProfile === 'partner' ? 'col-md-12' : 'col-md-8' ]"
                           >
                              <p v-if="userProfile === 'partner'" class="text-center">
                                 <strong>{{ `${$t('quantity_of_coupons_per_month')}` }}</strong>
                              </p>
                              <p v-else class="text-center">
                                 <strong>{{ `${$t('quantity_of')} ${$t('coupons')}` }}: {{ currentMonth }}, {{ currentYear }}</strong>
                              </p>

                              <div class="chart">
                                 <!-- Sales Chart Canvas -->
                                 <canvas id="myChart" height="120" style="height: 120px;"></canvas>
                              </div>
                              <!-- /.chart-responsive -->
                           </div>
                           <!-- /.col -->

                           <div v-if="userProfile !== 'partner'" class="col-md-4 mt-3">
                              <p class="text-center">
                                 <strong>{{ $t('qty_of_signatures') }} - {{ $t('Obtained') }}</strong>
                              </p>

                              <div v-for="(sig, idx) in qtySignaturesByMonth" :key="idx" class="progress-group">
                                 {{ getMonthName(sig.month) }}
                                 <span class="float-right"><b>{{ sig.qty }}</b></span>
                                 <div class="progress progress-sm">
                                    <div class="progress-bar bg-gradient-info" style="width: 100%"></div>
                                 </div>
                              </div>
                              <!-- /.progress-group -->
                           </div>
                           <!-- /.col -->

                        </div>
                        <!-- /.row -->

                     </div>
                     <!-- ./card-body -->
                     <div v-if="userProfile !== 'partner'" class="card-footer">
                        <p class="text-center">
                           <strong>{{ $t('signatures') }}</strong>
                        </p>
                        <div class="row">
                           <div v-for="(sigValue, idx) in totalSignaturesByMonth" :key="idx" class="col-sm-3 col-6">
                              <div class="description-block border-right">
                                 <span
                                    :class="['description-percentage', sigValue.percentage > 0 ? 'text-success' : sigValue.percentage < 0 ? 'text-danger' : 'text-warning']"
                                 >
                                    <i
                                       :class="['fas', sigValue.percentage > 0 ? 'fa-caret-up' : sigValue.percentage < 0 ? 'fa-caret-down' : 'fa-caret-left' ]"
                                    >
                                    </i>
                                    {{ sigValue.percentage }}%
                                 </span>
                                 <h5 class="description-header">R$ {{ sigValue.total }}</h5>
                                 <span class="description-text">{{ getMonthName(sigValue.month) }}</span>
                              </div>
                              <!-- /.description-block -->
                           </div>
                        </div>
                        <!-- /.row -->
                     </div>
                     <!-- /.card-footer -->
                  </div>
                  <!-- /.card -->
               </div>
               <!-- /.col -->
            </div>
            <!-- /.row -->

         </div><!--/. container-fluid -->
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import reportService from "../services/report-service";
import { mapState, mapActions } from "vuex";

export default {
   name: "Reports",
   data () {
      return {
         totalPartnerCoupons: null,
         partnerCouponsPerMonth: null,
         isLoading: false,
      }
   },
   computed: {
      ...mapState({
         userProfile: state => state.auth.user.data.profile,
         userId: state => state.auth.user.data.userId,
         getTableInfo: state => state.report.reports.getTableInfo,
         tableLabels: state => state.report.reports.tableLabels,
         tableData: state => state.report.reports.tableData,
         totalClients: state => state.report.reports.totalClients,
         totalComments: state => state.report.reports.totalComments,
         totalSignatures: state => state.report.reports.totalSignatures,
         totalSignaturesByMonth: state => state.report.reports.totalSignaturesByMonth,
         qtySignaturesByMonth: state => state.report.reports.qtySignaturesByMonth
      }),
      currentYear() {
         const now = new Date();
         return now.getFullYear();
      },
      currentMonth() {
         const now = new Date();
         const month = (now.getMonth() + 1);
         return this.getMonthName(month);
      }
   },
   async created() {
      this.isLoading = true;

      if (this.userProfile === "partner") {
         this.totalPartnerCoupons = await reportService.getTotalOfTimesThePartnerCouponWasUsed(this.userId);
         this.partnerCouponsPerMonth = await reportService.getQtyOfTimesByMonthThePartnerCouponWasUsed(this.userId);
      }
      else {
         if (this.getTableInfo.length === 0)
            await this.getAdminTableInformation();
         // this.totalSignaturesByMonth = await reportService.totalSignaturesByMonth();
         // this.qtySignaturesByMonth = await reportService.qtySignaturesByMonth();
         // this.totalComments = await reportService.totalComments();
         // this.totalSignatures = await reportService.totalSignatures();
         // this.totalClients = await reportService.totalClients();
         // this.getTableInfo = await reportService.getTableInfo();
         //
         // this.getTableInfo.forEach((item) => {
         //    this.tableLabels.push(item.name);
         //    this.tableData.push(item.qtyCoupons);
         // });
      }

      this.isLoading = false;

      setTimeout(() => {
         const ctx = document.getElementById('myChart');

         if (this.userProfile === 'partner') {

            const labels = [];
            const data = [];

            Object.entries(this.partnerCouponsPerMonth).map(([key, value]) => {

               const month = key.split("-")[1]; // Split the key on the '-' and take the second part (the month)
               labels.push(this.getMonthName(month));
               data.push(value);
            });

            new Chart(ctx, {
               type: 'bar',
               data: {
                  labels: labels,
                  datasets: [{
                     label: 'Quantidade por mês',
                     data: data,
                     borderWidth: 1
                  }]
               },
               options: {
                  scales: {
                     y: {
                        beginAtZero: true
                     }
                  }
               }
            });
         }
         else {
            new Chart(ctx, {
               type: 'doughnut',
               data: {
                  labels: this.tableLabels,
                  datasets: [{
                     label: 'Quantidade de cupons',
                     data: this.tableData,
                     backgroundColor: [
                        'rgb(229,146,165)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                        'rgb(255, 137, 86)',
                        'rgb(193, 86, 255)',
                        'rgb(255,86,86)',
                        'rgb(103,255,86)',
                        'rgb(165,224,183)',
                     ],
                     hoverOffset: 4
                  }]
               }
            });
         }
      }, 1000)
   },
   methods: {
      ...mapActions(['getAdminTableInformation']),
      getMonthName (monthNumber) {
         const months = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
         ];

         if (monthNumber >= 1 && monthNumber <= 12) {
            return months[monthNumber - 1];
         } else {
            return 'Número do mês inválido';
         }
      }
   }
}
</script>
